.welcome-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;

  background-color: #2a78ff;
  /* background-image: url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage.jpeg'); */
  background-image: url('///image.siuvo.com.cn/wechat/h5TitleBackgroundImage_nosmhc.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* .login-screen-content{
  padding-top: 50px;
} */
.welcome-container-body {
  /* height: 100vh;
  width: 100vw; */
}

.welcome-shufu-logo {
  padding: 16px;
  width: 30%;
  max-width: 200px;
}

.welcome-words {
  display: flex;
  align-items: center;
  padding: 16px;
}

.welcome-words-text {
  font-size: 32px;
  font-weight: bold;
}

.welcome-words-image {
  width: 100px;
  max-width: 100px;
  transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  /* IE 9 */
  -moz-transform: rotate(20deg);
  /* Firefox */
  -webkit-transform: rotate(20deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(20deg);
}

.welcome-title {
  color: white;
  margin-bottom: 250px;
  max-width: 250px;
  text-align: center;
  font-size: 20px;
}

.welcome-login-button {
  margin: 16px;
}

.login-with-patientIdentifier-entry {
  text-align: right;
  padding: 15px 20px 5px 0;
  line-height: 12px;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: #2979ff;
}

/*
覆盖label样式
*/
.welcome-container-body .list .item-floating-label,
.list .item-label {
  color: #136cfd
}

.login-screen-page .login-screen-content,
.login-screen-page .page-content,
.login-screen>.page .login-screen-content,
.login-screen>.page .page-content,
.login-screen>.view>.page .login-screen-content,
.login-screen>.view>.page .page-content {
  /* top: 15%;
  position: absolute; */
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  height: auto;
  max-height: 100%;
  width: 100%;
}
.list-item-content {
  background: #fff;
  margin: 10px 20px;
  padding: 15px;
  font-size: 16px;
  border-radius: 4px;
}
.test-name {
  line-height: 30px;
  font-weight: bold;
}
.item-user-name {
  margin-right: 10px;
}
.item-base-info-content {
  display: flex;
  align-items: center;
  margin: 8px 0;
  flex-wrap: wrap;
}
.item-start-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-start-btn {
  background: #2979ff;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}